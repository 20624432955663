/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../../components/seo"

import Page from "../../layouts/page"
import { Link } from "gatsby"

const styles = css`
iframe.document, embed.document {
  width: 100%;
  height: 100vh;
}
`

/*
  TODO
  Maybe use embed instead of iframe, iframe causes PDF to download on mobile.
*/

const GeneralTermsPage = () => {
  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <ol className="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/legal">Legal</Link></li>
          <li><span>General Terms of Service</span></li>
        </ol>
        
        <h1>General Terms of Service</h1>

        <iframe className="document" src="/legal/general_terms_of_service.pdf" title="General Terms of Service Document"></iframe>

      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="General Terms of Service"
    path="/legal/general-terms"
  />
)

export default GeneralTermsPage
